module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Robert Steilberg's home page","short_name":"Rob Steilberg","start_url":"/","background_color":"#0d2034","theme_color":"#ffffff","lang":"en","display":"standalone","icon":"src/images/favicon.png","icon_options":{"purpose":"maskable any"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"91b973e2018c87d5680835cbb7c021c8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
